import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from 'src/icons/ChevronRight';
import PlusIcon from 'src/icons/Plus';

const getBreadCrumb = (item) => {
  if (item.path === '#') {
    return (
      <Typography key={item.path} variant="subtitle2">
        {item.name}
      </Typography>
    );
  }
  return (
    <Link key={item.path} color="textPrimary" component={RouterLink} to={item.path} variant="subtitle2">
      {item.name}
    </Link>
  );
};

export const LayoutHeader = ({ actions, breadcrumbs, title, create }) => (
  <Grid container justifyContent="space-between" spacing={3}>
    <Grid item>
      <Typography color="textPrimary" variant="h5">
        {title}
      </Typography>

      <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ my: 2 }}>
        {!!breadcrumbs && breadcrumbs.map((item) => getBreadCrumb(item))}
      </Breadcrumbs>
    </Grid>
    <Grid item>
      <Grid>
        <Box
          sx={{
            mx: -1
          }}
        >
          {Children.toArray(actions).map((action) => React.cloneElement(action, { key: action.key }))}
          {!!create && (
            <RouterLink to={create.path} state={create.path.state}>
              <Button
                color="primary"
                sx={{ m: 1 }}
                variant="contained"
                key="create"
                startIcon={<PlusIcon fontSize="small" />}
              >
                {create.name}
              </Button>
            </RouterLink>
          )}
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

LayoutHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
  breadcrumbs: PropTypes.array,
  create: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string
  })
};

export default LayoutHeader;
