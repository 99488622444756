import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { logoutRequest, loginSuccess, loginFailure, loginRequest, pingRequest } from 'src/redux/actions/auth';
import * as UserSelectors from 'src/redux/selectors/user';
import { useTranslation } from 'react-i18next';
// import { useAuth as useAuthHF } from 'react-oauth2-pkce';
import { FormSchema } from '../models/schema';
import { fields } from '../models/login';
import { useNotify } from './useNotify';

export const useAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(UserSelectors.getAuthUser);
  const error = useSelector(UserSelectors.getAuthError);
  const navigate = useNavigate();
  const { success, error: errorMsg } = useNotify();
  // const { authService } = useAuthHF();
  const { schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.countryCode,
        fields.stateCode,
        fields.schoolCode,
        fields.trainingId,
        fields.password
      ]),
    []
  );

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      [fields.countryCode.name]: '',
      [fields.stateCode.name]: '',
      [fields.schoolCode.name]: '',
      [fields.trainingId.name]: '',
      [fields.password.name]: ''
    },
    resolver: schema
  });

  const { isSubmitting } = formState;

  const onLogin = useCallback(
    async (formData) => {
      const username = `${formData.countryCode}-${formData.stateCode}-${formData.schoolCode}-${formData.trainingId}`;
      try {
        const { data } = await dispatch(loginRequest({ username, password: formData.password }));

        if (data.exitcode === 1) {
          sessionStorage.setItem('access_token', data.token);
          success(t('auth.login.success'), { autoHideDuration: 2000 });
          dispatch(loginSuccess(data.data));
          if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1' ||
            window.location.hostname === ''
          ) {
            success('localhost login', { autoHideDuration: 2000 });
          } else {
            // console.log('call hf author');
            // authService.authorize();
          }
          navigate('/app/dashboard', { replace: true });
          return;
        }
        if (data.exitcode === 1001) {
          console.log(data);
          errorMsg(t('auth.login.multipleDeviceLogin'), { autoHideDuration: 10000 });
          // dispatch(loginFailure('login fail'));
          return;
        }
      } catch (e) {
        console.log(e);
        errorMsg(t('auth.login.error'));
        dispatch(loginFailure(e.message));
      }
    },
    [dispatch, navigate]
  );

  const onLogout = useCallback(() => {
    sessionStorage.removeItem('access_token');
    dispatch(logoutRequest());
  }, [dispatch]);

  const onPing = useCallback(async () => {
    try {
      const data = await pingRequest();
      sessionStorage.setItem('on_ping', 0);

      if (data.data.exitcode === 1001) {
        errorMsg(t('auth.login.multipleDeviceLogout'), { autoHideDuration: 10000 });
        sessionStorage.removeItem('access_token');
        dispatch(logoutRequest());
        return;
      }
      // localStorage.setItem('access_token', data.token);
      // success(t('auth.login.success'), { autoHideDuration: 2000 });
      // dispatch(loginSuccess(data.data));
      // navigate('/app/dashboard', { replace: true });
    } catch (e) {
      let onPingVal = Number(sessionStorage.getItem('on_ping') || '0');

      onPingVal += 1;
      sessionStorage.setItem('on_ping', onPingVal);

      if (onPingVal > 3) {
        errorMsg('you are offline');
      }

      if (onPingVal > 5) {
        dispatch(logoutRequest());
        sessionStorage.removeItem('access_token');
      }
      // errorMsg(t('auth.login.error'));
      // dispatch(loginFailure(e.message));
      // localStorage.removeItem('access_token');
    }
  }, [dispatch, navigate]);

  return {
    user,
    error,
    onLogin,
    handleSubmit,
    control,
    formState,
    isSubmitting,
    onLogout,
    onPing
  };
};

export const requireAuth = (NewComponent) => (props) => {
  const navigate = useNavigate();
  const user = useSelector(UserSelectors.getAuthUser);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return !!user && <NewComponent {...props} />;
};
