import * as yup from 'yup';
import GenericField from './genericField';

export default class IdField extends GenericField {
  constructor(name, label, { required = false, min = undefined, max = undefined, matches = undefined } = {}) {
    super(name, label);

    this.required = required;
    this.matches = matches;
    this.min = min;
    this.max = max;
  }

  forTable(overrides) {
    const defaultRender = undefined;

    const {
      title = this.label,
      sorter = true,
      dataIndex = this.name,
      render = defaultRender,
      ...others
    } = overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  }

  forView = (value) => value;

  forFormInitialValue = (value) => value;

  forForm = () => {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/);
    }

    return yupChain;
  };

  forFilter = () => yup.string().nullable(true).trim().label(this.label);

  forExport = () => yup.mixed().label(this.label);

  forImport = () => {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/);
    }

    return yupChain;
  };
}
