import i18n from 'i18next';
import _get from 'lodash/get';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import en from './en';
import vi from './vi';
import zh from './zh';

export const resources = {
  en: {
    translation: en
  },
  vi: {
    translation: vi
  },
  zh: {
    translation: zh
  }
};

//
export default i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LNG,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export { i18n };

setLocale(_get(resources.en, 'translation.validation'));
moment.updateLocale('en', _get(resources.en, 'translation.moment'));
