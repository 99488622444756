import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);
export const fields = {
  id: new IdField('id', 'id'),
  countryCode: new StringField('countryCode', label('countryCode'), placeholder('countryCode'), {
    length: 3,
    required: true
  }),
  stateCode: new StringField('stateCode', label('stateCode'), placeholder('stateCode'), { length: 3, required: true }),
  schoolCode: new StringField('schoolCode', label('schoolCode'), placeholder('schoolCode'), {
    length: 3,
    required: true
  }),
  trainingId: new StringField('trainingId', label('trainingId'), placeholder('trainingId'), {
    length: 4,
    required: true
  }),
  password: new StringField('password', label('password'), placeholder('password'), {
    required: true,
    min: 6,
    max: 64
  }),
  createdAt: new StringField('createdAt', label('createdAt'))
};
// eslint-disable-next-line
export default {
  fields,
  placeholder
};
