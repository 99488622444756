export default class GenericField {
  constructor(name, label, placeholder) {
    this.name = name;
    this.label = label;
    this.placeholder = placeholder;
  }

  forView = (value) => value;

  forFormInitialValue = (value) => value;

  forFilter = () => {
    throw new Error('Called superclass');
  };

  forForm = () => {
    throw new Error('Called superclass');
  };

  forExport = () => {
    throw new Error('Called superclass');
  };

  forImport = () => {
    throw new Error('Called superclass');
  };

  forTable(overrides) {
    const defaultRender = undefined;

    const {
      title = this.label,
      sorter = false,
      dataIndex = this.name,
      render = defaultRender,
      ...others
    } = overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  }
}
