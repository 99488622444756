/* eslint-disable no-return-await */
import axios from 'src/utils/axios';
import { useCallback } from 'react';
import axiosBase from 'axios';

export const useProgramBand = () => {
  const startProgramBand = useCallback(async () => {
    const data = await axios.post(`programband/new`, {});

    sessionStorage.setItem('program_band_log_id', data.data.logid);
    // console.log(data);
  });
  const logProgramBand = useCallback(async (msg, errorType) => {
    const id = sessionStorage.getItem('program_band_log_id');
    const data = await axios.post(`programband/log/${id}`, { msg, errorType });

    sessionStorage.setItem('program_band_log_id', data.data.logid);
    // console.log(data);
  });
  //   const endProgram = async (id) => {
  //     const logid = sessionStorage.getItem('program_log_id');
  //     await axios.patch(`programband/new`, { checkpointEnd: 1, logid });
  //   };

  //   const updateFFt = async (id, datafft) => {
  //     const logid = sessionStorage.getItem('program_log_id');
  //     await axios.patch(`programs/check/${id}`, { checkpointEnd: 2, logid, datafft });
  //   };

  // const getS3 = useCallback(async () => {
  //   const data = await axios.get(`s3/sign-s3`);
  //   // setFft(dataresult);

  //   console.log(data);

  //   return { data };
  // });

  // const getS3 = async () => {
  //   const data = useCallback(
  //     async () => await axios.get(`s3/sign-s3`)
  //     // setFft(dataresult);

  //     // console.log(data);
  //   );

  //   return { data };
  // };

  const getS3 = useCallback(async () => {
    const { data } = await await axios.get(`s3/sign-s3`);

    return data;
  });
  const uploadS3 = useCallback(async (s3data) => {
    const s3Urls = await getS3();
    console.log(s3Urls);
    const options = { headers: { 'Content-Type': 'text/plain' } };
    await axiosBase.put(s3Urls.signedRequest, JSON.stringify(s3data, options));

    // console.log(data);

    return s3Urls.url;
  });

  return {
    // endProgram,
    startProgramBand,
    logProgramBand,
    getS3,
    uploadS3
  };
};
