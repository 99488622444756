import { AppBar, Box, IconButton, Toolbar, Button } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { useEffect } from 'react';
// import PsychologyIcon from '@mui/icons-material/Psychology';
// import { useContext } from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';
import { useAuth } from '../../hooks/useAuth';
import { useNotify } from '../../hooks/useNotify';
// import SerialContext from '../../contexts/SerialContext';
const LogOutButton = styled(Button)({
  color: 'white'
});
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));
const useStyles = makeStyles({
  root: {
    minHeight: 64
  }
});

const DashboardNavbar = (props) => {
  const classes = useStyles();
  const { onSidebarMobileOpen, ...other } = props;
  const { error } = useNotify();
  const { t } = useTranslation();
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  // const serial = useContext(SerialContext);
  const handleLogout = async () => {
    try {
      await onLogout();
      navigate('/');
    } catch (err) {
      console.error(err);
      error('Unable to logout.');
    }
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar classes={{ root: classes.root }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <RouterLink to="/">
          <Logo height="50" width="50" />
        </RouterLink>
        <Menu className="horiz-menu">
          <MenuItem style={{ display: 'inline-block' }}>
            <RouterLink to="/app/dashboard">
              <LibraryBooksIcon height="50" width="50" />
            </RouterLink>
          </MenuItem>
          <MenuItem>My account</MenuItem>
          <MenuItem>Logout</MenuItem>
        </Menu>
        {/* <RouterLink to="/app/dashboard">
          <LibraryBooksIcon height="50" width="50" />
        </RouterLink> */}
        {/* <RouterLink to="/app/chart">
          <PsychologyIcon sx={{ fontSize: 40 }} />
        </RouterLink> */}
        {/* <IconButton
          onClick={() => {
            if (serial.canUseSerial) {
              serial.connect();
            }
          }}
        >

        </IconButton> */}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
          <LogOutButton fullWidth onClick={handleLogout} variant="outlined">
            {t('account.logOut')}
          </LogOutButton>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
