import { useProgramBand } from 'src/hooks/useProgramBand';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import axiosBase from 'axios';
import { useFft } from '../hooks/useFft';
import { useNotify } from '../hooks/useNotify';

export const SerialContext = createContext({
  canUseSerial: false,
  hasTriedAutoconnect: false,
  connect: () => Promise.resolve(false),
  disconnect: () => {},
  portState: 'closed',
  subscribe: () => () => {}
});

export const useSerial = () => useContext(SerialContext);

export const SerialProvider = ({ children }) => {
  const [portState, setPortState] = useState('closed');
  const { runFft } = useFft();
  const { error: errorMsg } = useNotify();
  const { logProgramBand, uploadS3 } = useProgramBand();
  const [canUseSerial] = useState(() => 'serial' in navigator);
  const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false);
  const [hasManuallyDisconnected, setHasManuallyDisconnected] = useState(false);
  // const [rawValue, setRawValue] = useState([]);
  const portRef = useRef(null);
  const readerRef = useRef(null);
  const readerClosedPromiseRef = useRef(Promise.resolve());
  function bufferToHex(buffer) {
    return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, '0').toUpperCase());
  }

  const currentSubscriberIdRef = useRef(0);
  const subscribersRef = useRef(new Map());
  /**
   * Subscribes a callback function to the message event.
   *
   * @param callback the callback function to subscribe
   * @returns an unsubscribe function
   */
  const subscribe = (callback) => {
    const id = currentSubscriberIdRef.current;
    subscribersRef.current.set(id, callback);
    currentSubscriberIdRef.current++;

    return () => {
      subscribersRef.current.delete(id);
    };
  };

  /**
   * Reads from the given port until it's been closed.
   *
   * @param port the port to read from
   */
  const readUntilClosed = async (port) => {
    // // eslint-disable-next-line no-undef
    // const decoder = new TextDecoderStream();

    // port.readable.pipeTo(decoder.writable);
    if (port.readable) {
      try {
        // const inputStream = decoder.readable;
        // const reader = inputStream.getReader();
        readerRef.current = port.readable.getReader();
        // let count = 0;
        // let contentlog = '';
        const currentLine = [];
        while (!hasManuallyDisconnected) {
          /* eslint-disable no-await-in-loop */
          const { value, done } = await readerRef.current.read();
          if (value) {
            const hexvalue = bufferToHex(value);

            currentLine.push(...hexvalue);
          }
          if (done) {
            console.log('[readLoop] DONE', done);
            // setRawValue([...rawValue, currentLine]);
            // reader.releaseLock();
            break;
          }
          // console.log(isConnected);
        }
        //  window.localStorage.setItem('currentLine', currentLine);
        if (currentLine.length > 32) {
          const storedData = window.localStorage.getItem('hls-user');

          if (storedData) {
            let hlsUser = JSON.parse(storedData);

            hlsUser = JSON.parse(hlsUser.user);

            const logid = sessionStorage.getItem('program_band_log_id');

            const itemData = {
              user: {
                _id: hlsUser.id,
                username: hlsUser.username,
                trainingId: logid,
                fullName: hlsUser.fullName,
                gender: hlsUser.gender,
                birthday: hlsUser.birthday,
                trainingReason: 'new program ',
                createdAt: new Date(),
                updatedAt: new Date()
              },
              data: '',
              callback_url: `${process.env.REACT_APP_BASE_URL}/programband/logprogram/${logid}`
            };

            const curstr = currentLine.join('');
            const delimite = 'A55A02';
            const myArray = curstr.split(delimite);
            console.log(curstr);
            let count = 0;
            const s3data = {};
            for (let index = 0; index < myArray.length; index++) {
              const curItem = `${delimite}${myArray[index]}`;
              if (curItem.length === 34) {
                s3data[count.toString()] = curItem.replace(/.{2}/g, '$& ').trim();
                count++;
              } else {
                console.log(curItem.length, curItem);
              }
            }

            uploadS3(s3data).then((publicUrl) => {
              itemData.data = publicUrl;
              try {
                runFft(itemData).then((res) => {
                  console.log(res.data);
                  //  updateFFt(selectedProgram._id, res.data);
                  // window.open(res.data.pdf, '_blank');
                  // window.open(res.data.fft[0], '_blank');
                  // window.open(res.data.fft[1], '_blank');
                });
                // console.log(itemData);
              } catch (e) {
                console.log(e);
                logProgramBand(`error on runFft data ${e}`, 'Error');
                errorMsg('error on get fft data');
                //   dispatch(loginFailure(e.message));
              }
            });

            // getS3().then((res) => {
            //   const s3Urls = res.data;
            //   if (!s3Urls.signedRequest) {
            //     logProgramBand(`S3 signedRequest is not defined`, 'Error');
            //     errorMsg('S3 signedRequest is not defined');
            //     throw new Error('S3 signedRequest is not defined');
            //   }
            //   axiosBase.put(s3Urls.signedRequest, JSON.stringify(s3data), options).then(() => {
            //     itemData.data = s3Urls.publicUrl;
            //   });
            // });
          } else {
            logProgramBand(`cannot get user info`, 'Error');
            errorMsg('Cannot get user info');
          }
        }
      } catch (error) {
        logProgramBand(`common error  ${error}`, 'Error');
        console.log(error);
        errorMsg('error ff1');
      } finally {
        //  setPortState('closed');
        readerRef.current.releaseLock();
      }
    }
  };

  /**
   * Attempts to open the given port.
   */
  const openPort = async (port) => {
    try {
      await port.open({ baudRate: 115200 });
      portRef.current = port;
      setPortState('open');
      setHasManuallyDisconnected(false);
    } catch (error) {
      setPortState('closed');
      console.error('Could not open port', error);
    }
  };

  const manualConnectToPort = async () => {
    if (canUseSerial && portState === 'closed') {
      setPortState('opening');
      // const filters = [
      //   // Can identify the vendor and product IDs by plugging in the device and visiting: chrome://device-log/
      //   // the IDs will be labeled `vid` and `pid`, respectively
      //   {
      //     usbVendorId: 0x1a86,
      //     usbProductId: 0x7523
      //   }
      // ];
      try {
        // const port = await navigator.serial.requestPort({ filters });
        const port = await navigator.serial.requestPort();
        await openPort(port);
        return true;
      } catch (error) {
        setPortState('closed');
        console.error('User did not select port', error);
      }
    }
    return false;
  };

  const autoConnectToPort = async () => {
    if (canUseSerial && portState === 'closed') {
      setPortState('opening');
      const availablePorts = await navigator.serial.getPorts();
      if (availablePorts.length) {
        const port = availablePorts[0];
        await openPort(port);
        return true;
      }
      setPortState('closed');

      setHasTriedAutoconnect(true);
    }
    return false;
  };

  const manualDisconnectFromPort = async () => {
    if (canUseSerial && portState === 'open') {
      const port = portRef.current;
      if (port) {
        setPortState('closing');

        // Cancel any reading from port
        readerRef.current?.cancel();
        await readerClosedPromiseRef.current;
        readerRef.current = null;

        // Close and nullify the port
        await port.close();
        portRef.current = null;

        // Update port state
        setHasManuallyDisconnected(true);
        setHasTriedAutoconnect(false);
        setPortState('closed');
      }
    }
  };

  /**
   * Event handler for when the port is disconnected unexpectedly.
   */
  const onPortDisconnect = async () => {
    // Wait for the reader to finish it's current loop
    await readerClosedPromiseRef.current;
    // Update state
    readerRef.current = null;
    readerClosedPromiseRef.current = Promise.resolve();
    portRef.current = null;
    // setHasManuallyDisconnected(true);
    setHasTriedAutoconnect(false);
    setPortState('closed');
  };

  // Handles attaching the reader and disconnect listener when the port is open
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const port = portRef.current;
    if (portState === 'open' && port) {
      // When the port is open, read until closed
      const aborted = { current: false };
      readerRef.current?.cancel();
      readerClosedPromiseRef.current.then(() => {
        if (!aborted.current) {
          readerRef.current = null;
          readerClosedPromiseRef.current = readUntilClosed(port);
        }
      });

      // Attach a listener for when the device is disconnected
      navigator.serial.addEventListener('disconnect', onPortDisconnect);

      return () => {
        aborted.current = true;
        navigator.serial.removeEventListener('disconnect', onPortDisconnect);
      };
    }
  }, [portState]);

  // Tries to auto-connect to a port, if possible
  useEffect(() => {
    if (canUseSerial) {
      navigator.serial.addEventListener('connect', (e) => {
        // Connect to `e.target` or add it to a list of available ports.
        console.log(e);
      });
    }
    if (canUseSerial && !hasManuallyDisconnected && !hasTriedAutoconnect && portState === 'closed') {
      autoConnectToPort();
    }
  }, [canUseSerial, hasManuallyDisconnected, hasTriedAutoconnect, portState]);

  return (
    <SerialContext.Provider
      value={{
        canUseSerial,
        hasTriedAutoconnect,
        subscribe,
        portState,
        connect: manualConnectToPort,
        disconnect: manualDisconnectFromPort
      }}
    >
      {children}
    </SerialContext.Provider>
  );
};

SerialProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SerialContext;
