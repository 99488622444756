export default {
  locale: 'en-US',
  form: {
    fields: {
      username: '登录号码',
      phoneNumber: 'Phone number',
      password: '密码',
      firstName: 'First name',
      lastName: 'Last name',
      gender: 'Gender',
      email: 'Email',
      address: 'Address',
      account: 'Account',
      dob: 'Date of birth',
      male: 'Male',
      female: 'Female',
      oldPassword: 'Old password',
      passwordConfirmation: 'Password confirmation',
      fullName: 'Full name',
      idNumber: 'Id number',
      name: 'Name',
      countryCode: 'Input 1',
      stateCode: 'Input 2',
      schoolCode: 'Input 3',
      trainingId: 'Input 4',
      expireDate: 'Expire Date',
      holdingNote: 'Holding note'
    },
    placeholder: {
      username: 'Input your user name',
      phoneNumber: 'Input your phone number',
      password: '请输入密码',
      firstName: 'Input your first name',
      lastName: 'Input your last name'
    }
  },
  auth: {
    login: {
      head: 'Login | BRAINSC',
      haveAccount: 'Do not have an account ?',
      register: 'Register',
      title: '视听融合',
      subtitle: '请登录',
      submit: '登录',
      success: 'Login successful',
      error: 'Login failed',
      multipleDevice: 'Duplicate login detected - 无法登录',
      multipleDeviceLogin:
        'Termination of Active Session on Another Window\nNotification\nOur system has detected an active session on another window or you have closed your browser without logging out. For security reasons, we will terminate the previous session. Click “Proceed” to continue.\n\n终止另一个网端上的活动\n通知\n我们的系统在另一个游览器上检测到相同的登录，或者您在系统未登出的状态下关闭浏览器。出于安全原因，我们将终止上一个登录。点击“确定”以继续。',
      multipleDeviceLogout:
        'You have logged out of your Online training system due to a new login on the other platform.\nPlease login again.\n\n由于您在其他游览器上进行了新的登录，现在您已经退出此训练系统。请重新登录。'
    },
    register: {
      head: 'Register | BRAINSC',
      haveAccount: 'You already have an account ?',
      login: 'Login',
      title: 'Register',
      subtitle: 'Required information to create an account',
      submit: 'Register',
      continue: 'Continue',
      back: 'Back',
      success: 'Register successful',
      error: 'Register failed'
    }
  },
  account: {
    account: 'Account',
    management: 'Management',
    general: 'General',
    security: 'Security',
    infoPersonal: 'Personal information',
    saveChanges: 'Save changes',
    passwordChange: 'Change password',
    passwordChangeBTN: 'Change password',
    setting: 'Setting',
    logOut: '退出'
  },
  sidebar: {
    overview: 'Overview',
    account: 'Account',
    settings: 'Setting',
    management: 'Management',
    dashboard: 'Overview',
    chart: 'Hardware'
  },
  dashboard: {
    helmet: 'BRAINSC',
    title: 'Overview',
    greeting: {
      title: 'Good {{time}}, {{name}}',
      caption: 'Here is the status of your project today'
    },
    complete: 'The program has been completed !'
  },
  moment: {
    relativeTime: {
      future: 'in %s',
      past: 'ago %s',
      s: 'few seconds ago',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d day',
      w: 'a week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  },
  common: {
    messages: {
      error: 'Error'
    },
    actions: {
      no: 'No',
      yes: 'Yes',
      save: 'Save',
      next: 'Next',
      back: 'Back',
      delete: 'Delete',
      submit: 'Submit',
      ingore: 'Ingore',
      create: 'Create',
      import: 'Import',
      export: 'Export',
      confirm: 'Confirm',
      search: 'Search',
      cancel: 'Cancel',
      reset: 'Reset',
      edit: 'Edit',
      update: 'Update',
      dismiss: 'Dismiss',
      restore: 'Restore',
      template: 'Download Template',
      restoreSelected: 'Restore All',
      deleteSelected: 'Delete All'
    },
    state: {
      on: 'On',
      off: 'Off',
      none: 'None',
      undefined: ''
    },
    modal: {
      preview: 'Preview'
    },
    days: {
      morning: 'morning',
      evening: 'evening',
      afternoon: 'afternoon'
    },
    labels: {
      actions: 'Actions'
    },
    status: {
      active: 'Active',
      deactive: 'Deactive',
      activated: 'Activated',
      deactivated: 'Deactivated'
    },
    list: {
      deactivate: {
        success: 'Delete {{item}} successfully!',
        failed: 'Delete {{item}} failed!',
        failure: 'Delete {{item}} failed!',
        confirm: 'Are you sure you want to delete {{item}} list is selected?'
      },
      result: 'Result {{total}} of {{records}} records'
    }
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
      required: '${path} bla'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.'
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue'
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.'
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported'
    }
  }
};
