import { Box, Container, Grid, Typography, Avatar } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks';
// import { getGreetingTime } from 'src/utils/common';
import { DashboardContainer } from 'src/containers/dashboard';
import { makeStyles } from '@mui/styles';
import { Color } from 'src/theme/color';
import getLabel from 'src/utils/getLabel';
import { useSelector } from 'react-redux';
import * as UserSelectors from 'src/redux/selectors/user';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useSettings from '../hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    paddingTop: 4
  },
  alertText: {
    opacity: 0.8,
    color: Color.active
  },
  caption: {
    paddingTop: 10,
    paddingBottom: 10
  },
  sectionText: {
    marginTop: 20,
    marginLeft: 20
  },
  sectionBody: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 40,
      margin: 20
    },
    marginTop: 20,
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderStyle: 'dashed',
    backgroundColor: Color.white,
    boxShadow: `0 2px ${Color.inactive}`
  },
  paddingXLUp: {
    [theme.breakpoints.up('1765')]: {
      padding: '0px !important'
    }
  }
}));
const Dashboard = () => {
  const { settings } = useSettings();
  const { onPing, onLogout } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const userProfile = useSelector(UserSelectors.getAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('access_token')) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    // Define your API call function
    const getAlerts = async () => {
      try {
        onPing();
        if (!sessionStorage.getItem('access_token')) {
          await onLogout();
          //  window.location.href = '/';
          window.location.reload();
        }
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
    };

    // Initial call to get alerts
    getAlerts();

    // Set up interval to call getAlerts every 10 seconds
    const interval = setInterval(getAlerts, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <>
      <Helmet>
        <title>{t('dashboard.helmet')}</title>
      </Helmet>
      <Box className={classes.container}>
        <Container maxWidth={settings.compact ? 'xl' : false} className={classes.paddingXLUp}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Box className={classes.sectionText}>
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.default',
                    borderRadius: 1,
                    display: 'flex',
                    overflow: 'hidden',
                    py: 2
                  }}
                >
                  <Avatar
                    // src={userProfile.avatar}
                    sx={{
                      cursor: 'pointer',
                      height: 48,
                      width: 48
                    }}
                  />
                  <Box sx={{ ml: 2 }}>
                    <Typography color="textPrimary" variant="h4">
                      {userProfile.username}
                    </Typography>
                    <Typography color="textSecondary" variant="h5">
                      {getLabel(userProfile.fullname)}
                    </Typography>
                  </Box>
                </Box>
                {/*  <Typography color="textPrimary" variant="h5">
                  {t('dashboard.greeting.title', {
                    time: getGreetingTime(t),
                    name: user?.username
                  })}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  {t('dashboard.greeting.caption')}
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <DashboardContainer />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
