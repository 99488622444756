/* eslint-disable no-return-await */
import axios from 'src/utils/axios';
import { useCallback, useEffect, useState } from 'react';

export const usePrograms = () => {
  const [programs, setPrograms] = useState();
  const [error, setError] = useState();

  const [historyLearn0, setHistoryLearn0] = useState();
  const [historyLearn1, setHistoryLearn1] = useState();
  const [historyLearn2, setHistoryLearn2] = useState();

  const getPrograms = async () => await axios.get('/programs');
  const startProgram = useCallback(async (id) => {
    const data = await axios.patch(`programs/check/${id}`, { checkpointStart: 1 });

    sessionStorage.setItem('program_log_id', data.data.logid);
    // console.log(data);
  });
  const endProgram = async (id) => {
    const logid = sessionStorage.getItem('program_log_id');
    await axios.patch(`programs/check/${id}`, { checkpointEnd: 1, logid });
  };

  const updateFFt = async (id, datafft) => {
    const logid = sessionStorage.getItem('program_log_id');
    await axios.patch(`programs/check/${id}`, { checkpointEnd: 2, logid, datafft });
  };

  const getS3 = async () => {
    const data = useCallback(
      async () => await axios.get(`s3/sign-s3`)
      // setFft(dataresult);

      // console.log(data);
    );

    return { data };
  };

  const historyLearn = async (id) => await axios.post(`programs/getbymonth/${id}`);
  useEffect(() => {
    getPrograms()
      .then((res) => setPrograms(res?.data?.data))
      .then((res) => setError({ success: res?.data?.isSuccess, error: res?.data?.msg }));

    historyLearn(0).then((res) => {
      setHistoryLearn0(res?.data?.data);
    });
    historyLearn(-1).then((res) => setHistoryLearn1(res?.data?.data));
    historyLearn(-2).then((res) => setHistoryLearn2(res?.data?.data));
  }, []);

  return {
    programs,
    setPrograms,
    error,
    endProgram,
    startProgram,
    updateFFt,
    historyLearn0,
    historyLearn1,
    historyLearn2,
    getS3
  };
};
