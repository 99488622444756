/* eslint-disable no-return-await */
import axios from 'src/utils/axiosfft';
import { useCallback } from 'react';

export const useFft = () => {
  // const [fft, setFft] = useState();

  const runFft = useCallback(
    async (data) => await axios.post(`api/v1/fft/run_fft`, data)
    // setFft(dataresult);

    // console.log(data);
  );

  return { runFft };
};
