import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '../containers/login';
import Logo from '../components/Logo';

const Login = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title> {t('auth.login.head')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Container maxWidth="sm" sx={{ py: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              my: 8
            }}
          >
            <RouterLink to="/">
              <Logo height="120" width="120" />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    {t('auth.login.title')}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {t('auth.login.subtitle')}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 5
                }}
              >
                <LoginForm />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
