export const Color = {
  white: '#FFF',
  primary: '#1E1E1E',
  background: '#2B2B2B',
  text: '#FFF',
  border: '#949494',
  card: '#575454',
  error: '#FF2300',
  warning: '#FFD500',
  info: '#FF9900',
  status: '#04B404',
  active: '#D61B0A',
  inactive: '#9E9E9E',
  inputBorder: '#F6F6F7',
  black: '#000000'
};
