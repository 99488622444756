// import { matchPath } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers';
import * as React from 'react';
import dayjs from 'dayjs';
import { ListItem, List } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
// import { styled } from '@mui/material/styles';
// import isSameDay from 'date-fns/isSameDay';
import { usePrograms } from '../../hooks/usePrograms';

// const CustomPickersDay = styled(PickersDay, {
//   shouldForwardProp: (prop) => prop !== 'selected'
// })(({ theme, selected }) => ({
//   ...(selected && {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover, &:focus': {
//       backgroundColor: theme.palette.primary.dark
//     },
//     borderTopLeftRadius: '50%',
//     borderBottomLeftRadius: '50%',
//     borderTopRightRadius: '50%',
//     borderBottomRightRadius: '50%'
//   })
// }));
const NavCalendars = () => {
  const { historyLearn0, historyLearn1, historyLearn2 } = usePrograms();
  // console.log(historyLearn0);

  const date0 = dayjs();
  const date1 = dayjs().add('-1', 'M');
  const date2 = dayjs().add('-2', 'M');

  const findDate = (dates, date) => {
    const datetmp2 = dayjs(date);
    let lc = false;
    for (let index = 0; index < dates.length; index++) {
      const datetmp = dayjs(dates[index]);
      lc = datetmp.isSame(datetmp2, 'day');
      // console.log(datetmp, datetmp2, lc);
      if (lc === true) break;
    }
    return lc;
    // return dates.find((item) => item.getTime() === dateTime);
  };

  // const findIndexDate = (dates, date) => {
  //   const dateTime = date.getTime();
  //   return dates.findIndex((item) => item.getTime() === dateTime);
  // };

  // const renderPickerDay0 = (date, selectedDates, pickersDayProps) => {
  //   if (!historyLearn0) {
  //     return <PickersDay {...pickersDayProps} />;
  //   }

  //   console.log(historyLearn0, 'xxxx');
  //   const selected = findDate(historyLearn0, date);

  //   return <CustomPickersDay {...pickersDayProps} disableMargin selected={selected} />;
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <List>
        <ListItem>
          <CalendarPicker
            disablePast
            readOnly
            disable
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={historyLearn2}
            defaultCalendarMonth={date2}
            // eslint-disable-next-line react/jsx-no-bind
            // renderDay={renderPickerDay0}
            // defaultCalendarMonth={new Date(2022, 10, 1)}
            onChange={(newValue) => {
              //  setValue(newValue);
              console.log('xxx', newValue);
            }}
            renderDay={(day, selectedDays, pickersDayProps) => {
              let selectedMuiClass = '';
              if (!historyLearn2) {
                return <PickersDay {...pickersDayProps} />;
              }
              // Pseudo-code here! You will have to use the proper functions from the
              // date-fns library to evaluate if 'day' is in your dateArray.

              if (findDate(historyLearn2, day)) {
                selectedMuiClass = 'Mui-selected';
              }

              return <PickersDay className={selectedMuiClass} {...pickersDayProps} />;
            }}
          />{' '}
        </ListItem>
        <ListItem>
          <CalendarPicker
            disablePast
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={historyLearn1}
            defaultCalendarMonth={date1}
            onChange={(newValue) => {
              //  setValue(newValue);
              console.log(newValue);
            }}
            renderDay={(day, selectedDays, pickersDayProps) => {
              let selectedMuiClass = '';
              if (!historyLearn1) {
                return <PickersDay {...pickersDayProps} />;
              }
              // Pseudo-code here! You will have to use the proper functions from the
              // date-fns library to evaluate if 'day' is in your dateArray.

              if (findDate(historyLearn1, day)) {
                selectedMuiClass = 'Mui-selected';
              }

              return <PickersDay className={selectedMuiClass} {...pickersDayProps} />;
            }}
          />{' '}
        </ListItem>
        <ListItem>
          <CalendarPicker
            disablePast
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={historyLearn0}
            defaultCalendarMonth={date0}
            onChange={(newValue) => {
              //  setValue(newValue);
              console.log(newValue);
            }}
            renderDay={(day, selectedDays, pickersDayProps) => {
              let selectedMuiClass = '';
              if (!historyLearn0) {
                return <PickersDay {...pickersDayProps} />;
              }
              // Pseudo-code here! You will have to use the proper functions from the
              // date-fns library to evaluate if 'day' is in your dateArray.

              if (findDate(historyLearn0, day)) {
                selectedMuiClass = 'Mui-selected';
              }

              return <PickersDay className={selectedMuiClass} {...pickersDayProps} />;
            }}
          />
        </ListItem>
      </List>
    </LocalizationProvider>
  );
};

NavCalendars.propTypes = {};

export default NavCalendars;
