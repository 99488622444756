import { createTypes } from 'reduxsauce';

export const ModalTypes = createTypes(`
  MODAL_SHOW
  MODAL_CLOSE
  MODAL_REFRESH
`);

export const modalShow = (modal, data) => ({
  type: ModalTypes.MODAL_SHOW,
  modal,
  data
});

export const modalClose = (modal) => ({ type: ModalTypes.MODAL_CLOSE, modal });

export const modalRefresh = (modal) => ({
  type: ModalTypes.MODAL_REFRESH,
  modal
});
