import { combineReducers } from 'redux';
import modalReducer from './modal';
import currentUser from './currentUser';

const rootReducer = combineReducers({
  currentUser,
  modal: modalReducer
});

export default rootReducer;
