import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import _get from 'lodash/get';
import NumberField from '../../components/FormControls/NumberField';
import PasswordField from '../../components/FormControls/PasswordField';
import { fields } from '../../models/login';
import { useAuth } from '../../hooks/useAuth';

const LoginForm = (props) => {
  const { t } = useTranslation();
  const { control, formState, isSubmitting, onLogin, handleSubmit } = useAuth();
  const { errors = {} } = formState;

  return (
    <form noValidate {...props} onSubmit={handleSubmit(onLogin)}>
      <FormControl
        fullWidth
        required
        error={
          !!_get(errors, `${fields.countryCode.name}.message`) ||
          !!_get(errors, `${fields.stateCode.name}.message`) ||
          !!_get(errors, `${fields.schoolCode.name}.message`) ||
          !!_get(errors, `${fields.trainingId.name}.message`)
        }
      >
        <FormLabel> {t('form.fields.username')}</FormLabel>
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <NumberField
            maxLength={3}
            autoFocus
            fullWidth
            name={fields.countryCode.name}
            control={control}
            formState={formState}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            maxLength={3}
            autoFocus
            fullWidth
            name={fields.stateCode.name}
            control={control}
            formState={formState}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            maxLength={3}
            autoFocus
            fullWidth
            name={fields.schoolCode.name}
            control={control}
            formState={formState}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            maxLength={4}
            autoFocus
            fullWidth
            name={fields.trainingId.name}
            control={control}
            formState={formState}
          />
        </Grid>
      </Grid>
      <PasswordField
        required
        fullWidth
        hideIcon
        label={fields.password.label}
        name={fields.password.name}
        placeholder={fields.password.placeholder}
        autoComplete="current-password"
        control={control}
        formState={formState}
      />
      <Box sx={{ mt: 2 }}>
        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          {t('auth.login.submit')}
        </Button>
      </Box>
    </form>
  );
};

export default LoginForm;
