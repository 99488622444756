import PropTypes from 'prop-types';

const Logo = ({ white, ...props }) => (
  <img
    alt="Logo"
    height="40px"
    width="160px"
    src={`/static/images/logo-brainsc${white ? '-white' : ''}.png`}
    {...props}
  />
);

Logo.propTypes = {
  white: PropTypes.bool
};

export default Logo;
