import 'react-app-polyfill/ie11';
import '../node_modules/video-react/dist/video-react.css';
import Slide from '@mui/material/Slide';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import { SerialProvider } from './contexts/SerialContext';
import store, { persistor } from './redux/store';

const LoadingOverlay = () => <div>Loading</div>;

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

const style = {
  marginTop: 4
};

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <SnackbarProvider anchorOrigin={anchorOrigin} TransitionComponent={Slide} maxSnack={3} style={style}>
            <SerialProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SerialProvider>
          </SnackbarProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
