import { Box, Divider, Drawer } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import FolderOpenIcon from '../../icons/FolderOpen';
import Logo from '../Logo';
import NavSection from '../navbar/NavSection';
import NavCalendars from '../navbar/NavCalendar';
import Scrollbar from '../Scrollbar';

const drawerWidth = 400;

const openedMixin = (theme) => ({
  zIndex: 1,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  zIndex: 1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 20px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 20px)`
  }
});

const DrawerCustom = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: 1,
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const DrawerContainer = styled('div')({
  position: 'relative'
});

const IconButtonSidebar = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })(({ open }) => ({
  top: '50%',
  right: open ? '-13%' : '-23%',
  position: 'absolute',
  zIndex: 10
}));

const companySections = (t) => [
  {
    title: t('sidebar.management'),
    items: [
      {
        title: t('sidebar.dashboard'),
        path: '/app/dashboard',
        icon: <FolderOpenIcon />
      }
      // {
      //   title: t('sidebar.chart'),
      //   path: '/app/chart',
      //   icon: <FolderOpenIcon />
      // }
    ]
  }
];
const DashboardSidebar = (props) => {
  const { t } = useTranslation();

  const { onMobileClose, openMobile, open, setOpen } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const sections = useMemo(() => companySections(t), []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
      setOpen(false);
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo height="50" width="50" />
          </RouterLink>
        </Box>
        {/* <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Avatar
              // src={user.avatar}
              sx={{
                cursor: 'pointer',
                height: 48,
                width: 48
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.username}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {getLabel(user.fullname)}
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              isOpenDrawer={open}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          <NavCalendars />
          {/* <div>1234xxx</div> */}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <DrawerContainer>
        {open ? (
          <IconButtonSidebar onClick={handleDrawerClose} open={open} sx={{ padding: '40px' }}>
            <ArrowCircleLeftIcon sx={{ fontSize: 40 }} />
          </IconButtonSidebar>
        ) : (
          <IconButtonSidebar onClick={handleDrawerOpen} open={open}>
            <ArrowCircleRightIcon sx={{ fontSize: 40 }} />
          </IconButtonSidebar>
        )}
        <DrawerCustom
          anchor="left"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="permanent"
        >
          {content}
        </DrawerCustom>
      </DrawerContainer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  setOpen: PropTypes.func,
  openMobile: PropTypes.bool,
  open: PropTypes.bool
};

export default DashboardSidebar;
