import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import routes from 'src/routes';
import { AuthProvider, AuthService } from 'react-oauth2-pkce';
import { createCustomTheme } from './theme';
import useSettings from './hooks/useSettings';

const authService = new AuthService({
  clientId: process.env.REACT_APP_CLIENT_ID || 'CHANGEME',
  clientSecret: process.env.REACT_APP_SECRECT_ID,
  location: window.location,
  // provider: process.env.REACT_APP_PROVIDER || 'https://sandbox.auth.ap-southeast-2.amazoncognito.com/oauth2',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
  scopes: ['public', 'write', 'update'],
  authorizeEndpoint: process.env.REACT_APP_AUTHORIZE_ENPOINT || window.location.origin,
  tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || window.location.origin,
  logoutEndpoint: process.env.REACT_APP_REVOKE_ENDPOINT
});

const App = () => {
  const routing = useRoutes(routes());
  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  function handelRightClick(event) {
    event.preventDefault();
  }
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider authService={authService}>{routing}</AuthProvider>
      {/* {routing} */}
    </ThemeProvider>
  );
};

export default App;
