/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable prefer-const */
/* eslint-disable radix */
import { Grid } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Chip from '@mui/material/Chip';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
// import { useAuth as useAuthHF } from 'react-oauth2-pkce';
// import SerialContainer from './SerialContainer';
import { usePrograms } from '../../hooks/usePrograms';
// import { useAuth } from '../../hooks/useAuth';
// import { useNotify } from '../../hooks/useNotify';
// import { useFft } from '../../hooks/useFft';
// import SerialContext from '../../contexts/SerialContext';

export const DashboardContainer = () => {
  const { programs, startProgram, endProgram } = usePrograms();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const playerRef = useRef();
  const interValRefPlayer = useRef();
  const interValRefAudio = useRef();
  const [statePlayer, setStatePlayer] = useState();
  const [second, setSecond] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);
  const [fiveSecondCountdown, setFiveSecondCountdown] = useState(false);
  const [playlist, setPlayList] = useState([]);
  const [idProgram, setIdProgram] = useState();
  const [endVideo, setEndVideo] = useState(0);
  const [endAudioBGM, setEndAudioBGM] = useState(0); /// 0 : 0 xai, 1: dang choi, 2: dung
  // const [disable, setDisable] = useState(false);
  const [totalProgramDuration, setTotalProgramDuration] = useState(600);
  const [totalProgramDurationStr, setTotalProgramDurationStr] = useState(59);

  // const [isShowGetHeadBand, setIsShowGetHeadBand] = useState(false);
  // const { runFft } = useFft();
  // const [fftData, setFftData] = useState([]);

  // const login = async () => {
  //   authService.authorize();
  // };
  // const logout = async () => {
  //   authService.logout();
  // };
  // const { authService } = useAuthHF();
  // const serial = useContext(SerialContext);
  // useEffect(() => {
  //   if (serial.canUseSerial) {
  //     if (!authService.isAuthenticated()) {
  //       authService.authorize();
  //     }
  //   }
  // }, []);

  // console.log(authTokens);
  const [countPlayOrPause, setCountPlayOrPause] = useState(0);
  const int = [];
  const history = useNavigate();
  const random = Math.floor(
    Math.random() * playlist[endVideo]?.item?.timing.filter((item) => item.seconds !== null)?.length
  );
  const audio = document.createElement('audio');
  // audiobgm.ref = audioBGMRef;
  audio.src = `/static/files/audio/${
    playlist[endVideo]?.item?.ding?.name ??
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'
  }`;
  // audio.src =
  // ('data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV');

  const audioRef = useRef(new Audio());
  function startBGMAudio(isStart, bgmfile) {
    // audio.src = `/static/files/audio/${playlist[endVideo]?.item?.ding?.name}`;
    if (isStart) {
      audioRef.current.src = `/static/files/audiobgm/${bgmfile}`;
      // audiobgm.src = `/static/files/audiobgm/${bgmfile}`;
      setEndAudioBGM(1);
      audioRef.current.addEventListener(
        'ended',
        () => {
          // here goes your code to change the icon
          setEndAudioBGM(2);
        },
        true
      );
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }

  function pauseVideo() {
    // audioRef.current?.pause();
    setCountPlayOrPause(countPlayOrPause + 1);
  }

  const handleClickOpen = () => {
    document.webkitExitFullscreen();
    setOpen(true);
  };
  // const { onPing } = useAuth();
  const handleClose = () => {
    setOpen(false);
    setEndVideo(0);
    history('/');
  };

  useEffect(
    () => () => {
      clearInterval(interValRefPlayer.current);
      clearInterval(interValRefAudio.current);
      audio.pause();
    },
    []
  );
  const dateForm = (secondsAmount = 0) => {
    const d = Number(secondsAmount);
    // const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    // const hDisplay = h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00';
    const mDisplay = m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
    const sDisplay = s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : '00';
    return `${mDisplay}:${sDisplay}`;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (countPlayOrPause % 2 === 0) {
        if (totalProgramDuration > 0) {
          setTotalProgramDuration(totalProgramDuration - 1);

          const myVar = dateForm(totalProgramDuration);
          setTotalProgramDurationStr(myVar);
        } else {
          closeProgram(false);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (playerRef.current && playVideo) {
      playerRef.current.subscribeToStateChange(handleBind);
    }
  }, [playVideo]);

  useEffect(
    () => () => {
      audioRef.current?.pause();
      playerRef.current?.pause();
      console.log('in cleanup');
    },
    []
  );

  useEffect(() => {
    if (countPlayOrPause % 2 !== 0) {
      clearInterval(interValRefPlayer.current);
      clearInterval(interValRefAudio.current);
      playerRef?.current?.pause();
      audioRef?.current?.pause();
      audio.pause();
      console.log('pause');
    } else if (countPlayOrPause > 0) {
      console.log(countPlayOrPause);
      audioRef?.current?.play();
    }

    // 0 second pause
    if (playVideo && statePlayer?.currentTime === 0 && !second.includes(statePlayer?.currentTime)) {
      int.push(0);
      setSecond(int);
      playerRef.current.pause();

      // audioRef.current.pause();
      console.log(new Date(), 'run handle autido 3000');
      handleAudio(3000);
      handleStopStartVideo(3100);
    }

    if (playVideo && endAudioBGM === 2) {
      console.log('audio stop');
      endProgram(idProgram);
      clearInterval(interValRefPlayer.current);
      clearInterval(interValRefAudio.current);
      playerRef.current.pause();
      audioRef.current.pause();
      audio.pause();
      handleClickOpen();
    }

    if (playVideo && statePlayer?.currentTime > 1 && !second.includes(parseInt(statePlayer?.currentTime))) {
      int.push(parseInt(statePlayer?.currentTime));
      setSecond(int);

      // loop pause
      playerRef.current.pause();
      audio.pause();
      console.log(new Date(), 'run handle audio random');
      handleAudio(playlist[endVideo]?.item?.timing[random]?.seconds * 1000);
      handleStopStartVideo(playlist[endVideo]?.item?.timing[random]?.seconds * 1100);

      // next video
      if (parseInt(statePlayer?.currentTime) === parseInt(statePlayer?.duration)) {
        if (endVideo < playlist.length - 1) {
          setEndVideo(endVideo + 1);
        } else if (endVideo === playlist.length - 1) {
          if (endAudioBGM === 0) {
            endProgram(idProgram);
            clearInterval(interValRefPlayer.current);
            clearInterval(interValRefAudio.current);
            playerRef.current.pause();
            audioRef.current.pause();
            audio.pause();
            handleClickOpen();
            if (statePlayer?.isFullscreen) {
              playerRef.current.toggleFullscreen();
            }
          } else {
            setEndVideo(0);
          }
        }
      }
    }
  }, [playVideo, statePlayer, second, endVideo, countPlayOrPause, audio, endAudioBGM]);

  const handleStopStartVideo = (miLiSecond) => {
    if (interValRefPlayer.current) {
      clearInterval(interValRefPlayer.current);
    }
    interValRefPlayer.current = setInterval(() => {
      playerRef.current?.play();
    }, miLiSecond);
  };

  const handleAudio = (miLiSecond) => {
    if (interValRefAudio.current) {
      clearInterval(interValRefAudio.current);
    }
    interValRefAudio.current = setInterval(() => {
      audio.play();
    }, miLiSecond);
  };

  function handleBind(state) {
    setStatePlayer(state);
  }
  // const sleep = async (milliseconds) => {
  //   await new Promise((resolve) => setTimeout(resolve, milliseconds));
  // };

  async function beginProgram(id) {
    const program = programs.find((element) => element._id === id);
    window.localStorage.setItem('program', JSON.stringify(program));

    // await sleep(5000);
    setPlayVideo(true);
    setPlayList(program.playlist);
    setTotalProgramDuration(program.totalProgramDuration || 600);

    const myVar = dateForm(program.totalProgramDuration || 600);
    setTotalProgramDurationStr(myVar);
    startProgram(program._id);
    startBGMAudio(program.bgm, program.audio?.title);
  }

  async function closeProgram(isForceClose) {
    if (idProgram) {
      endProgram(idProgram);
      clearInterval(interValRefPlayer.current);
      clearInterval(interValRefAudio.current);
      playerRef?.current?.pause();
      // audio.pause();
      // if (serial.canUseSerial) {
      //   serial.disconnect();
      // }
      if (!isForceClose) {
        audioRef?.current?.pause();
        handleClickOpen();
      }
    }
  }

  // const [currentCount, setCount] = useState(1);
  // const timer = () => setCount(currentCount + 1);

  // useEffect(() => {
  //   onPing();
  //   const id = setInterval(timer, 5000);
  //   return () => clearInterval(id);
  // }, [currentCount]);
  // useEffect(() => {
  //   let isMounted = true;
  //   (async function getStatus() {
  //     await onPing();
  //     // const json = await res.json();

  //     setTimeout(getStatus, 5000);
  //     if (isMounted) {
  //       console.log('a');
  //     }
  //     // console.log('ran', json);
  //   })();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  // navigator.serial.addEventListener('connect', (e) => {
  //   // Connect to `e.target` or add it to a list of available ports.
  //   console.log(e);
  // });
  // async function checkPortConnected() {
  //   if (navigator.serial) {
  //     await connectSerial();
  //   } else {
  //     errorMsg('Web Serial API not supported.');
  //   }
  // }

  // let keepReading = true;
  // let reader;

  // function disconnect() {
  //   keepReading = false;
  //   setDisable(false);
  //   // reader.releaseLock();

  //   // reader.cancel();
  // }
  // function bufferToHex(buffer) {
  //   return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, '0').toUpperCase());
  // }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setFiveSecondCountdown(false);
      beginProgram(idProgram);
    }

    return (
      <div style={{ display: 'flex', 'flex-direction': 'column', 'align-items': 'center' }}>
        <div className="text">Waiting</div>
        <div style={{ 'font-size': '40px' }}>{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  let showinfo;
  if (fiveSecondCountdown) {
    showinfo = (
      <Box sx={{ display: 'flex', 'justify-content': 'center' }}>
        <CountdownCircleTimer
          isPlaying
          duration={5}
          colors={['#004777', '#F7B801', '#A30000', '#A30000']}
          colorsTime={[5, 3, 1, 0]}
          onComplete={() => ({ shouldRepeat: false, delay: 1 })}
          sx={{ left: '50%', transform: 'translateX(-50%)' }}
          size="550"
        >
          {renderTime}
        </CountdownCircleTimer>
      </Box>
    );
  } else if (playVideo) {
    showinfo = (
      <Box>
        <Grid item xs={12}>
          <Grid item xs={12} onClick={() => pauseVideo()}>
            <Player
              ref={playerRef}
              autoPlay
              muted
              src={`${process.env.REACT_APP_BASE_URL}/videos/${playlist[endVideo]?.item?.video._id}`}
            >
              <ControlBar disableDefaultControls className="my-class" />
              <BigPlayButton position="center" />
            </Player>
          </Grid>
          <Grid container style={{ backgroundColor: 'black' }} display="flex">
            <Grid item md={2}>
              <Chip label={totalProgramDurationStr} style={{ color: 'white', border: 'none' }} variant="outlined" />
            </Grid>
            <Grid container item xs={8} justify="center" />
            <Grid item md={2} container direction="column" alignItems="flex-end" justify="flex-end">
              <Button
                variant="contained"
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  float: 'right',
                  // backgroundColor: '#ff8d8d',
                  //  boxShadow: '0 0 4px 2px rgba(0,0,0,.2)',
                  cursor: 'pointer',
                  // height: 40,
                  outline: 'none'
                  //  borderRadius: '100%',
                  //  width: 40
                }}
                // className={classes.button}
                startIcon={playVideo && countPlayOrPause % 2 !== 0 ? <PauseIcon /> : <PlayArrowIcon />}
                onClick={() => pauseVideo()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    showinfo = (
      <CardMedia
        component="img"
        image={
          ` ${process.env.REACT_APP_BASE_URL}/public/img/${playlist[endVideo]?.item?.video?.thumbnail}` === 'undefined'
            ? ` ${process.env.REACT_APP_BASE_URL}/public/img/${playlist[endVideo]?.item?.video?.thumbnail}`
            : '/static/images/poster.png'
        }
      />
    );
  }
  return (
    <Grid container rowSpacing={3}>
      {/* <SerialContainer /> */}
      <Grid item xs={12} sx={{ paddingLeft: 10, paddingRight: 10, 'justify-content': 'center' }}>
        {showinfo}
      </Grid>

      {programs &&
        programs.map((program) => (
          <Grid item key={program._id}>
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
              <Typography variant="h4">{program.title} </Typography>
              <IconButton
                onClick={() => {
                  closeProgram(true);
                  setPlayVideo(false);
                  setIdProgram(program._id);
                  setFiveSecondCountdown(true);
                  setCountPlayOrPause(0);
                  // setPlayVideo(true);
                  // setPlayList(program.playlist);
                  // setTotalProgramDuration(program.totalProgramDuration || 600);
                  // startProgram(program._id);
                  // startBGMAudio(program.bgm, program.audio?.title);
                }}
              >
                <PlayCircleIcon sx={{ fontSize: 40 }} />
              </IconButton>
              {/* {idProgram && (
                <IconButton
                  onClick={() => {
                    if (serial.canUseSerial) {
                      serial.connect();
                    }
                  }}
                >
                  <PsychologyIcon sx={{ fontSize: 40 }} />
                </IconButton>
              )} */}
            </Box>

            <Grid>
              {program.playlist.map((item) => (
                <Grid item key={item._id} style={{ width: '15%' }}>
                  <CardMedia
                    component="img"
                    image={
                      `${process.env.REACT_APP_BASE_URL}/public/img/${item.item.video.thumbnail}` ||
                      '/static/images/poster.png'
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dashboard.complete')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
