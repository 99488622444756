import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { THEMES } from 'src/constants/theme';
import useSettings from 'src/hooks/useSettings';
import { useTranslation } from 'react-i18next';

const getValues = (settings) => ({
  compact: settings.compact,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme
});

const SettingsContainer = () => {
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  const { t } = useTranslation();
  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
  };

  return (
    <Card>
      <CardHeader title={t('setting.setting')} />
      <Divider />
      <CardContent>
        <Grid container spacing={3} justifyItems="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item md={4} />
              <Grid item md={5} xs={12}>
                <TextField
                  fullWidth
                  label={t('setting.interface')}
                  name="theme"
                  onChange={(event) => handleChange('theme', event.target.value)}
                  select
                  SelectProps={{ native: true }}
                  value={values.theme}
                  variant="outlined"
                >
                  {Object.keys(THEMES).map((theme) => (
                    <option key={theme} value={theme}>
                      {theme
                        .split('_')
                        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                        .join(' ')}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={4} />
              <Grid item md={5} sx={12} style={{ marginLeft: '8px' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.responsiveFontSizes}
                      color="primary"
                      edge="start"
                      name="responsiveFontSizes"
                      onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
                    />
                  }
                  label={
                    <div>
                      {t('setting.responsiveFontSizes')}
                      <Typography color="textSecondary" component="p" variant="caption">
                        {t('setting.textResponsiveFontSizes')}
                      </Typography>
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={4} />
              <Grid item md={5} sx={12} style={{ marginLeft: '8px' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.compact}
                      color="primary"
                      edge="start"
                      name="compact"
                      onChange={(event) => handleChange('compact', event.target.checked)}
                    />
                  }
                  label={
                    <div>
                      {t('setting.compact')}
                      <Typography color="textSecondary" component="p" variant="caption">
                        {t('setting.textCompact')}
                      </Typography>
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={4} />
              <Grid item md={5} sx={12} style={{ marginLeft: '8px' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.roundedCorners}
                      color="primary"
                      edge="start"
                      name="roundedCorners"
                      onChange={(event) => handleChange('roundedCorners', event.target.checked)}
                    />
                  }
                  label={
                    <div>
                      {t('setting.roundedCorners')}
                      <Typography color="textSecondary" component="p" variant="caption">
                        {t('setting.textRoundedCorners')}
                      </Typography>
                    </div>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button color="primary" onClick={handleSave} variant="contained">
          {t('setting.saveSettings')}
        </Button>
      </Box>
    </Card>
  );
};

export default SettingsContainer;
