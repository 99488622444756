import { Navigate } from 'react-router';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';

import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
// import Chart from './pages/Chart';
import Settings from './pages/settings';

const userRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'chart', element: <Chart /> },
      { path: 'settings', element: <Settings /> }
    ]
  }
];

const publicRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      // { path: '/chart', element: <Navigate to="/app/chart" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];
export default () => [...userRoutes, ...publicRoutes];
