import { Box } from '@mui/material';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import SettingsContainer from 'src/containers/settings';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutContainer
        title={t('setting.setting')}
        header={
          <LayoutHeader
            title={t('setting.setting')}
            breadcrumbs={[
              {
                path: '/app/dashboard',
                name: `${t('setting.management')}`
              },
              {
                path: '#',
                name: `${t('setting.setting')}`
              }
            ]}
          />
        }
      >
        <Box>
          <SettingsContainer />
        </Box>
      </LayoutContainer>
    </>
  );
};

export default Settings;
