import { createTypes } from 'reduxsauce';
import axios from 'src/utils/axios';

export const AuthTypes = createTypes(`
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE
  LOGOUT
`);

export const loginSuccess = (user) => ({ type: AuthTypes.LOGIN_SUCCESS, user });

export const loginFailure = (error) => ({
  type: AuthTypes.LOGIN_FAILURE,
  error
});

export const logoutRequest = () => ({ type: AuthTypes.LOGOUT });

export const loginRequest =
  ({ username, password }) =>
  (dispatch) => {
    dispatch({ type: AuthTypes.LOGIN_REQUEST });
    return axios.post('/auth/login', { username, password });
  };

export const pingRequest = () =>
  // dispatch({ type: AuthTypes.LOGIN_SUCCESS });
  axios.post('users/ping');
