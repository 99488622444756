export default {
  locale: 'vi',
  form: {
    fields: {
      username: 'Tên đăng nhập',
      phoneNumber: 'Số điện thoại',
      password: 'Mật khẩu',
      firstName: 'Tên',
      lastName: 'Họ và tên đệm',
      gender: 'Giới tính',
      email: 'Email',
      address: 'Địa chỉ',
      account: 'Tài khoản',
      dob: 'Ngày sinh',
      male: 'Nam',
      female: 'Nữ',
      oldPassword: 'Mật khẩu cũ',
      passwordConfirmation: 'Xác nhận mật khẩu',
      fullName: 'Họ và tên',
      idNumber: 'Chứng minh nhân dân',
      name: 'Tên'
    },
    placeholder: {
      username: 'Nhập tên đăng nhập',
      phoneNumber: 'Nhập số điện thoại',
      password: 'Nhập mật khẩu',
      firstName: 'Nhập Tên',
      lastName: 'Nhập Họ và tên đệm'
    }
  },
  auth: {
    login: {
      head: 'Đăng nhập | BRAINSC',
      haveAccount: 'Bạn chưa có tài khoản ?',
      register: 'Đăng ký',
      title: 'Đăng nhập',
      subtitle: 'Đăng nhập để sử dụng',
      submit: 'Đăng nhập',
      success: 'Đăng nhập thành công',
      error: 'Đăng nhập thất bại'
    },
    register: {
      head: 'Đăng kí | BRAINSC',
      haveAccount: 'Bạn đã có tài khoản ?',
      login: 'Đăng Nhập',
      title: 'Đăng kí',
      subtitle: 'Thông tin yêu cầu để tạo tài khoản',
      submit: 'Đăng kí',
      continue: 'Tiếp tục',
      back: 'Trở lại',
      success: 'Đăng kí thành công',
      error: 'Đăng kí thất bại'
    }
  },
  account: {
    account: 'Tài khoản',
    management: 'Quản lý',
    general: 'Cá nhân',
    security: 'Bảo mật',
    infoPersonal: 'Thông tin cá nhân',
    saveChanges: 'Lưu thay đổi',
    passwordChange: 'Thay đổi mật khẩu',
    passwordChangeBTN: 'Đổi mật khẩu',
    setting: 'Cài đặt',
    logOut: 'Đăng xuất'
  },
  sidebar: {
    overview: 'Tổng quan',
    account: 'Tài khoản',
    settings: 'Cài đặt',
    management: 'Quản lý',
    registerATM: 'Đăng kí',
    dashboard: 'Tổng quan'
  },
  dashboard: {
    helmet: 'BRAINSC',
    title: 'Tổng quan',
    greeting: {
      title: 'Chào {{time}}, {{name}}',
      caption: 'Dưới đây là tình hình dự án của bạn ngày hôm nay'
    }
  },
  moment: {
    relativeTime: {
      future: 'in %s',
      past: 'cách đây %s',
      s: 'vài giây trước',
      ss: '%d giây',
      m: 'một phút',
      mm: '%d phút',
      h: 'một giờ',
      hh: '%d giờ',
      d: 'một ngày',
      dd: '%d ngày',
      w: 'một tuần',
      ww: '%d tuần',
      M: 'một tháng',
      MM: '%d tháng',
      y: 'một năm',
      yy: '%d năm'
    }
  },
  common: {
    actions: {
      dismiss: 'Hủy bỏ',
      confirm: 'Đồng ý',
      search: 'Tìm kiếm',
      reset: 'Đặt lại',
      create: 'Tạo mới',
      update: 'Cập nhật',
      change: 'Thay đổi',
      delete: 'Xóa',
      cancel: 'Hủy bỏ',
      add: 'Thêm',
      edit: 'Chỉnh sửa',
      save: 'Lưu',
      template: 'Tải file mẫu',
      import: 'Nhập'
    },
    label: {
      action: 'Hành động'
    },
    message: {
      notFound: 'Không có dữ liệu'
    },
    days: {
      morning: 'buổi sáng',
      afternoon: 'buổi chiều',
      evening: 'buổi tối'
    }
  }, // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} không hợp lệ',
      required: '${path} không được để trống',
      oneOf: '${path} phải thuộc một trong những giá trị sau: ${values}',
      notOneOf: '${path} không được là một trong các giá trị: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} phải là một ${type}`;
      }
    },
    string: {
      length: '${path} phải chứa chính xác ${length} kí tự',
      min: '${path} phải chứa ít nhất ${min} kí tự',
      max: '${path} không được vượt quá ${max} kí tự',
      matches: '${path} phải thỏa cú pháp sau: "${regex}"',
      email: '${path} phải là email hợp lệ',
      url: '${path} phải là url hợp lệ',
      trim: '${path} phải xóa khoảng trắng phía sau',
      lowercase: '${path} chỉ gồm kí tự thường',
      uppercase: '${path} chỉ gồm kí tự hoa',
      selected: '${path} phải được chọn'
    },
    number: {
      min: '${path} phải lớn hơn hoặc bằng ${min}',
      max: '${path} phải bé hơn hoặc bằng ${max}',
      lessThan: '${path} phải bé hơn ${less}',
      moreThan: '${path} phải lớn hơn ${more}',
      notEqual: '${path} phải khác ${notEqual}',
      positive: '${path} phải là một số dương',
      negative: '${path} phải là một số âm',
      integer: '${path} phải là một số nguyên'
    },
    date: {
      min: '${path} giá trị phải sau ngày ${min}',
      max: '${path} giá trị phải trước ngày ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} trường không được chứa các khóa không được chỉ định'
    },
    array: {
      min: '${path} trường phải có ít nhất ${min} phần tử',
      max: '${path} trường phải có số phần tử không vượt quá ${max}'
    }
  }
};
